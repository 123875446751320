<template>
  <!-- <div v-if="loadingSpinnerFlag" class="bg-[#1D1D1D] z-50 fixed w-full h-full">
    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
  </div>
  <router-view v-else/> -->

  <div class="flex justify-center absolute top-[20px] left-[50%] translate-x-[-50%]">
            <img src="@/mobileApp/assets/sportkit_logo.svg" alt="">
        </div>
        <!-- <div class="flex flex-col align-middle items-center justify-center w-full lg:w-[400px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            <div class="flex justify-center">
                <img src="@/mobileApp/assets/logo.svg" class="logo w-[140px]" >
            </div>
            <div class="flex justify-center w-10/12 text-white text-[21px] mb-[18px] mt-[45px]">
                Hello coach!
            </div>
            <div class="bg-[#474849] bg-opacity-50 rounded-[9px] items-center px-[13px] border-[1px] border-[#8C8C8C] flex h-[40px] justify-center w-[90%] mb-[16px]">
                <div class="text-[#91CBE5] ">+380</div>
                <input v-model="trainerLogin" type="text" class="bg-transparent text-center rounded-[9px] outline-none text-[#91CBE5] px-3 w-full placeholder-[#8C8C8C] placeholder:text-[13px] placeholder:relative pr-[50px]" placeholder="номер телефону">
            </div>
            <div class="bg-[#474849] bg-opacity-50 rounded-[9px] items-center px-[13px] border-[1px] border-[#8C8C8C] flex h-[40px] justify-center w-[90%] mb-[16px]">
                <input v-model="trainerPassword" type="password" class="bg-transparent text-center rounded-[9px] outline-none text-[#91CBE5] px-3 w-full placeholder-[#8C8C8C] placeholder:text-[13px]" placeholder="пароль">
            </div>
            <div class="flex justify-center w-[90%] mt-[10px] mb-[63px]">
                <button @click="sendAuthData" class="bg-[#91CBE5] border-[1px] border-[#FFFFFF] rounded-[9px] items-center px-[13px] flex h-[40px] justify-center w-full mb-[16px]">
                    <div class="w-full text-[20px] font-extrabold">Log in</div>

                </button>
            </div>
        </div> -->
        <div class="text-white absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            Десктопна версія тренера наразі недоступна
        </div>
        <div 
            class="absolute bottom-[20px] left-[50%] translate-x-[-50%]"
            :class="{
                'w-[300px]' : screenWidth > 700,
                'w-full' : screenWidth <= 700
            }"    
        >
            <!-- <div class="text-[#91CBE5] text-[10px] lg:text-center lg:w-full lg:mx-0 mb-[22px] mx-10">
                Привіт!<br>Це віртуальний кабінет для тренерів ФК Минай, якщо у тебе виникли проблеми з доступом, звернися до представника клубу за телефоном: +380 ХХ ХХХ ХХ ХХ
            </div> -->
            <div class="text-[#fff] text-[11px] text-center w-full">
                © 2023 ALL RIGHTS RESERVED
            </div>
        </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useMainStore } from './stores/mainStore'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'main-view',

    setup() {
        const store = useMainStore()
        const router = useRouter()
        const route = useRoute()

        const loadingSpinnerFlag = ref(true)

        const logout = () => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            router.push('/login')
            loadingSpinnerFlag.value = false
        }

        onMounted(async () => {
          if (localStorage.getItem('accessToken')) {
            router.push('/')
          }

          if (route.path == '/login') {
            loadingSpinnerFlag.value = false
            return
          }
          try {
            const resAuth = await store.getTrainerData()
            if (resAuth.status == 422) {
              console.log('error')
              logout()
            }
            await store.getStudentBalanceData()
            await store.getServiceBalanceData()
            await store.getTrainerGroups()
            await store.getAllTrainerPlanedTrainings()
            await store.getTrainerRealIncomes()
            loadingSpinnerFlag.value = false
          } catch (e) {
            console.log(e)
          }
        })

        return {
            logout,
            loadingSpinnerFlag
        }
    },
}

</script>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #1D1D1D;
  }
</style>