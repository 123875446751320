<template>
    <div class="bg-[#1D1D1D] fixed pb-[30px] h-[100%] overflow-y-scroll w-full mt-[-10px]">
        <image-viewer 
            class="fixed top-0 z-[150]"
            v-if="showNewImageViewer"
            :image="newCurrentImage"
            @close="showNewImageViewer = false"
            @deletePhoto="deleteNewPhoto(newCurrentImage)"
        />
        <FileView 
            class="fixed top-0 z-[150]"
            :fileUrl="currentImage"
            @close="showImageViewer = false"
            @delete="additionalFiles.splice(additionalFiles.indexOf(currentImage), 1); showImageViewer = false"
            v-if="showImageViewer"
        />
        <div @click="$emit('close')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div v-if="loadingSpinnerFlag" class="bg-[#1D1D1D] fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
        </div>
        <div v-else>

            <div class="border-b-[1px] border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
                РЕДАГУВАННЯ ТРЕНУВАННЯ
            </div>
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Тип тренування
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <select v-model="trainingType" class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]">
                                <option value="none" class="bg-[#252526]">Не задано</option>
                                <option value="training" class="bg-[#252526]">Тренування</option>
                                <option value="gym" class="bg-[#252526]">Спортзал</option>
                                <option value="competition" class="bg-[#252526]">Змагання</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Дата
                    </div> -->
                    <div class="flex justify-center items-center">
                        <input v-model="currentTraining.date" class="custom-date-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1 px-2 w-[90%] " type="date">
                    </div>
                </div>
            </div>
            <div class="mt-[32px]">
                <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                    Час тренування
                </div> -->
                <div class="text-center mb-2 text-[#FFF] text-[12px] font-light">
                    оберіть час тренування (початок - кінець)
                </div>
                <div class="flex justify-center items-center">
                    <input v-model="currentTraining.start_time" class="custom-time-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] p-1" type="time">
                    <span class="font-bold mx-2 text-[#FFF] text-[20px]">></span>
                    <input v-model="currentTraining.end_time" class="custom-time-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] p-1" type="time">
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Група
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <input
                                @change="changeGroupText($event.target.value)"
                                :value="groupText"
                                @focus="groupOptionsShow = true"
                                @blur="groupOptionsShow = false" 
                                class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                placeholder="оберіть групу зі списку"
                            />
                            <div v-if="groupOptionsShow" class="absolute top-[36px] bg-[#252526] z-30 w-[85%] border-b-[1px] border-x-[1px] border-[#8C8C8C]">
                                <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 py-2 bg-[#252526] bg-opacity-40 text-[#FFF] cursor-pointer">{{groupOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Локація
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <input
                                @change="changeLocationText($event.target.value)"
                                :value="locationText"
                                @focus="locationOptionsShow = true"
                                @blur="locationOptionsShow = false" 
                                class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                placeholder="оберіть локацію зі списку"
                            />
                            <div v-if="locationOptionsShow" class="absolute top-[36px] bg-[#252526] z-30 w-[85%] border-b-[1px] border-x-[1px] border-[#8C8C8C]">
                                <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 py-2 bg-[#252526] bg-opacity-40 text-[#FFF] cursor-pointer">{{locationOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Коментар
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <textarea
                                v-model="description"
                                class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                rows="4"
                                placeholder="коментар до події"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Тип тренування
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <select :value="trainerId" @change="changeTrainer" class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]">
                                <option value="" class="bg-[#252526]">Тренер не заданий</option>
                                <option v-for="trainer in allTrainers" :value="trainer.trainer_id" class="bg-[#252526]">{{ trainer.name + ' ' + trainer.surname }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px] flex px-[25px] items-center justify-between">
                <div class="flex">
                    <div v-if="additionalFiles.length == 0 && newAdditionalFiles.length == 0" class="text-white opacity-25">Файли незакріплені</div>
                    <div v-for="file in additionalFiles" @click="showImageViewer = true; currentImage = file" class="cursor-pointer mr-[5px]">
                        <img src="@/mobileApp/assets/picture_icon.svg" alt="">
                    </div>
                    <div v-for="file in newAdditionalFiles" @click="showNewImageViewer = true; newCurrentImage = file" class="cursor-pointer mr-[5px]">
                        <img src="@/mobileApp/assets/picture_icon.svg" alt="">
                    </div>
                </div>
                <div>
                    <img src="@/mobileApp/assets/upload_icon_light.svg" class="cursor-pointer" @click="uploadAdditionalPhoto">
                    <input 
                        ref="fileInput"
                        type="file" 
                        class="hidden"
                        @change="newAdditionalFiles.push($event.target.files[0])"
                    />
                </div>
            </div>

            <button @click="updateTraining" class="bg-[#4EAE3B] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-10 mx-auto">
                <div class="w-full">Змінити тренування</div>
            </button>
            <button @click="deleteTraining" class="bg-[#F9245A] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-2 mx-auto">
                <div class="w-full">Видалити тренування</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, deleteTrainingById, updateTrainingById, getTrainingById, getAllTrainers } from '../../services/apiRequests'

    import ImageViewer from './ImageViewer.vue'
    import FileView from './FileView.vue'

    async function compressImageToBlobAsync(file, maxWidth, maxHeight, quality) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                }

                if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Получаем Blob сжатого изображения
                canvas.toBlob(
                function (blob) {
                    if (blob) {
                    // Создаем новый объект File на основе Blob
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                    resolve(compressedFile);
                    } else {
                    reject(new Error('Ошибка при создании Blob'));
                    }
                },
                'image/jpeg',
                quality / 100
                );
            };

            img.onerror = function () {
                reject(new Error('Ошибка при загрузке изображения'));
            };
            };

            reader.onerror = function () {
            reject(new Error('Ошибка при чтении файла'));
            };

            reader.readAsDataURL(file);
        });
    }


    export default {
        name: 'UpdateTrainingForm',

        props: {
            selectedTraining: {
                type: String,
                default: ''
            },
        },

        setup(props, { emit }) {
            const group = ref(null)
            const groupText = ref('')
            
            const location = ref(null)
            const locationText = ref('')

            const groupOptions = ref([])
            const locationOptions = ref([])

            const currentTraining = ref({})
            const trainingType = ref('none')
            const description = ref('')

            const loadingSpinnerFlag = ref(true)

            const additionalFiles = ref([])

            const fileInput = ref(null)
            const newAdditionalFiles = ref([])

            const currentImage = ref(null)
            const newCurrentImage = ref(null)

            const allTrainers = ref([])
            const trainerId = ref('')
            const trainerLabel = ref('')

            const changeTrainer = (e) => {
                trainerId.value = e.target.value
                trainerLabel.value = e.target.options[e.target.selectedIndex].text
            }

            const deleteNewPhoto = (photo) => {
                newAdditionalFiles.value = newAdditionalFiles.value.filter(file => file.name !== photo.name)
            }

            onBeforeMount(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const trainingRes = await getTrainingById(props.selectedTraining)
                currentTraining.value = trainingRes.data
                currentTraining.value.date = currentTraining.value.date.split('T')[0].split('-').join('-')
                trainingType.value = currentTraining.value.type ?? 'none'

                additionalFiles.value = currentTraining.value.additional_files ?? []
                console.log('additionalFiles.value ------- ', additionalFiles.value)

                groupText.value = currentTraining.value.group_label
                locationText.value = currentTraining.value.location_label

                description.value = currentTraining.value.description

                trainerId.value = currentTraining.value.trainer_id
                trainerLabel.value = currentTraining.value.trainer_label

                const trainersRes = await getAllTrainers()
                allTrainers.value = trainersRes.data

                group.value = groupOptions.value.find(group => group.name == groupText.value)
                location.value = locationOptions.value.find(location => location.location_id == currentTraining.value.location_id)
                loadingSpinnerFlag.value = false
                console.log('location.value ------- ', location.value)
                console.log('currentTraining.value ------- ', currentTraining.value)
            })

            const uploadAdditionalPhoto = () => {
                fileInput.value.click()
            }

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                location.value = locationOption
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const showImageViewer = ref(false)
            const showNewImageViewer = ref(false)

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: '00:00',
                end: '00:00'
            })

            const deleteTraining = async () => {
                loadingSpinnerFlag.value = true
                deleteTrainingById(props.selectedTraining).then(res => {
                    console.log('res ----- ', res)
                    emit('updateTrainings')
                    emit('close')
                    loadingSpinnerFlag.value = false
                })
            }

            const updateTraining = async () => {
                loadingSpinnerFlag.value = true
                trainingType.value = trainingType.value == 'none' ? null : trainingType.value
                let newAdditionalFilesCompressed = []
                for (let i = 0; i < newAdditionalFiles.value.length; i++) {
                    const file = newAdditionalFiles.value[i]
                    const compressedFile = await compressImageToBlobAsync(file, 600, 600, 100)
                    newAdditionalFilesCompressed.push(compressedFile)
                }
                console.log('location.value ------- ', location.value)
                await updateTrainingById(
                    props.selectedTraining,
                    group.value.name,
                    group.value.group_id,
                    location.value.name,
                    location.value.location_id,
                    currentTraining.value.start_time,
                    currentTraining.value.end_time,
                    currentTraining.value.date,
                    trainingType.value,
                    description.value,
                    trainerId.value,
                    trainerLabel.value,
                    additionalFiles.value,
                    newAdditionalFilesCompressed,
                )
                emit('updateTrainings')
                emit('close')
                loadingSpinnerFlag.value = false
            }

            return { 
                group, 
                groupText, 
                groupOptions, 
                groupVisibleOptions, 
                changeGroupText, 
                groupOptionsShow,
                setGroup,
                location,
                locationText,
                locationOptions,
                locationVisibleOptions,
                changeLocationText,
                locationOptionsShow,
                setLocation,
                trainingTime,
                currentTraining,
                deleteTraining,
                updateTraining,
                trainingType,
                description,
                loadingSpinnerFlag,
                additionalFiles,
                fileInput,
                newAdditionalFiles,
                uploadAdditionalPhoto,
                currentImage,
                showImageViewer,
                newCurrentImage,
                showNewImageViewer,
                deleteNewPhoto,
                allTrainers,
                trainerId,
                trainerLabel,
                changeTrainer
            }
        },

        components: {
            ImageViewer,
            FileView
        }
    }
</script>


<style lang="scss" scoped> 
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: url('../../assets/white_calendar.svg');
    cursor: pointer;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    background-image: url('../../assets/clock_icon.svg');
    cursor: pointer;
}
</style>