<template>
    <div class="mt-[87px]">
        <div class="text-white flex justify-between mb-[7px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[15px] font-semibold">МОЇ ТРЕНУВАННЯ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>
        <div>
            <button @click="$router.push('/select-group-for-training')" :disabled="store.allPlanedTrainings.length == 0" class="border-[#4EAE3B] border-[1px] rounded-[9px] disabled:opacity-75 text-[#4EAE3B] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-3 mx-auto">
                <div class="w-full">Провести тренування</div>
            </button>
            <div :key="key" class="student-data-table overflow-y-scroll mt-1 mx-4 h-[268px] rounded-2xl py-1">
                <!-- <div v-if="store.allPlanedTrainings.length == 0" class="h-full flex flex-col items-center justify-center w-full text-center text-[#A1D9F7] font-semibold">
                    <div class="text-xl">Тренувань немає...</div>
                    <div class="flex items-center">
                        <span class="text-[41px]">4</span>
                        <img src="../../assets/ball_icon.svg" class="w-[31px] mx-1">
                        <span class="text-[41px]">4</span>
                    </div>
                </div>
                <div v-else v-for="training in store.allPlanedTrainings" class="flex text-[#A1D9F7] justify-between border-b-[0.2px] border-[#A1D9F7] border-opacity-25 items-center py-3">
                    <div class="font-light text-lg w-[7.91%] truncate">{{ getWeekDayShortNameByDate(training.date) }}</div>
                    <div class="font-bold text-base text-center w-[32.73%] truncate">{{ formateDate(training.date) }}</div>
                    <div class="font-light text-base w-[14.55%] truncate ">{{ training.start_time }}</div>
                    <div class="font-light text-base px-1 w-[21.82%] truncate">{{ training.location_label }}</div>
                    <div class="font-bold text-base text-end px-1 w-[23.00%] truncate">{{ training.group_label }}</div>
                </div> -->
                <div class="mt-[5px] overflow-y-scroll px-[5px] message-box">
                    <div v-for="training in store.allPlanedTrainings" class="w-full flex h-[27px] items-center rounded-[4px] border-[1px] border-green-400 mb-[9px]">
                        <div class="h-full rounded-l-[4px] bg-green-400 w-[5px]"></div>
                        <div class="px-[14px] w-full flex text-[#FFF] align-middle">
                            <div class="w-[10%] align-middle text-[14px] ">
                                {{ getWeekDayShortNameByDate(training.date) }}.
                            </div>
                            <div class="w-[25%] text-[14px] text-start font-semibold align-middle">
                                {{ training.date.split('T')[0].split('-').reverse().join('.') }}
                            </div>
                            <div class="w-[20%] text-[14px] text-center align-middle">
                                {{ training.start_time }}
                            </div>
                            <div class="w-[30%] text-[14px] truncate text-center align-middle">
                                {{ training.location_label }}
                            </div>
                            <div class="w-[15%] font-bold text-[14px] truncate text-end align-middle">
                                {{ training.group_label }}
                            </div>
                        </div>
                    </div>
                    
                    <div v-for="training in (8 - store.allPlanedTrainings.length > 0 ? 8 - store.allPlanedTrainings.length : 0)" class="w-full flex h-[27px] items-center rounded-[4px] border-[1px] border-[#42413f] mb-[9.2px]">
                        <div class="h-full rounded-l-[4px] bg-gray-400 w-[5px]"></div>
                        <div class="px-[1vw] w-full flex text-[#FFF] align-middle">
                            <div class="w-full text-[14px] opacity-60 text-center align-middle">
                                Відсутнє заплановане тренування
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button @click="$router.push('/completed-trainings')" class="bg-[#4EAE3B] rounded-[9px] text-[#1D1D1D] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
                <div class="w-full">Проведені тренування</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#1D1D1D"/>
                </svg>
            </button>
            <button @click="$router.push('/add-new-training')" class="bg-[#A1D9F7] rounded-[9px] text-[#1D1D1D] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-2 mx-auto">
                <div class="w-full">Додати тренування</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#1D1D1D"/>
                </svg>
            </button> -->
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from "vue"
    import { uuid } from "vue3-uuid"
    import { useMainStore } from "../../stores/mainStore" 

    export default {
        name: "trainings-block",

        setup() {
            const store = useMainStore()
            const key = ref(uuid.v4())

            const getWeekDayShortNameByDate = (date) => {
                const weekDays = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const dateObj = new Date(date)
                return weekDays[dateObj.getDay()]
            }

            const formateDate = (date) => {
                const dateObj = new Date(date)

                const monthDay = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
                const month = dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1
                const year = dateObj.getFullYear()

                return `${monthDay}.${month}.${year}`
            }

            onMounted(() => {
                key.value = uuid.v4()
            })

            return {
                store,
                getWeekDayShortNameByDate,
                formateDate,
                key
            }
        }
    }
</script>
