import { defineStore } from 'pinia'
import { getTrainerDataRequest, getStudentBalanceDataRequest, getAssistantGroupsRequest, getTrainerGroupsRequest, getTransactionsByBalanceIdRequest, getPlanedTrainingsByGroupId, getStudentsByGroupIdRequest, getStudentVirtualBalanceRequest, addVirtualIncomeRequest, addRealIncomeRequest, getRealIncomesByBalanceIdRequest, getTrainingsByTrainerId } from '../services/apiRequests'

export const useMainStore = defineStore('main', {
    state: () => ({
        trainer: {},
        service_balance: 0,
        student_balance: 0,
        trainer_groups: [],
        allTrainings: [],
        allTrainerIncomes: [],
        allTrainerTransactions: [],

        currentTrainingStudentsList: [],
        currentTraining: {},

        currentGroup: {},
        allStudentsInGroup: [],
        currentStudent: {},

        currentCompletedTraining: {},

        currentDayTrainings: [],
        currentDayForFullView: new Date()
    }),
    
    getters: {
        allPlanedTrainings: (state) => state.allTrainings.filter(training => training.status === 'planed')
    },
    
    actions: {
        async getTrainerData() {
            const res = await getTrainerDataRequest()
            console.log('res.data', res.data)
            this.trainer = res.data
            return res
        },

        async getStudentsInGroupForView() {
            const res = await getStudentsByGroupIdRequest(this.currentGroup.group_id)
            this.allStudentsInGroup = res.data
        },

        async getStudentBalanceData() {
            const res = await getStudentBalanceDataRequest(this.trainer.student_balance_id)
            this.student_balance = res.data.balance_state
        },

        async getServiceBalanceData() {
            const res = await getStudentBalanceDataRequest(this.trainer.service_balance_id)
            this.service_balance = res.data.balance_state
        },

        async getTrainerGroups() {
            const res = await getTrainerGroupsRequest(this.trainer.trainer_id)
            const assistantsRes = await getAssistantGroupsRequest(this.trainer.trainer_id)
            this.trainer_groups = [...res.data, ...assistantsRes.data]
        },

        async getAllTrainerPlanedTrainings() {

            this.allTrainings = []
            // let allPlanedTrainings = []

            const allTrainingsResponse = await getTrainingsByTrainerId(this.trainer.trainer_id)
            console.log('allTrainingsResponse.data ----- ', allTrainingsResponse.data)

            // for (let i = 0; i < this.trainer_groups.length; i++) {
            //     const res = await getPlanedTrainingsByGroupId(this.trainer_groups[i].group_id)
            //     console.log('res.data --------- ', res.data)
            //     allPlanedTrainings.push(...res.data)
            // }

            this.allTrainings.push(...allTrainingsResponse.data)
            this.allTrainings.sort((a, b) => {
                return new Date(a.date) - new Date(b.date)
            })

        },

        async getAllStudentByGroupId(groupId) {
            const res = await getStudentsByGroupIdRequest(groupId)
            this.currentTrainingStudentsList = res.data
        },

        async getAllStudentsByTrainerGroups() {
            const result = []
            for (let i = 0; i < this.trainer_groups.length; i++) {
                const res = await getStudentsByGroupIdRequest(this.trainer_groups[i].group_id)
                result.push(...res.data)
            }
            return result
        },
        
        async getVirtualStudentBalance(balance_id) {
            const res = await getStudentVirtualBalanceRequest(balance_id)
            return res.data.balance_state
        },

        async proccessIncomeTransaction(trainerStudentBalanceId, studentVirtualBalanceId, amount, trainerName, studentName) {
            const studentVirtualIncomeLabel = `Поповнення балансу через тренера ${trainerName}`
            const virtualIncomeResponse = await addVirtualIncomeRequest(studentVirtualBalanceId, amount, studentVirtualIncomeLabel)

            const virtualTxId = virtualIncomeResponse.data.tx_id
            const trainerStudentIncomeLabel = `Поповнення балансу від студента ${studentName}`
            const trainerStudentIncomeResponse = await addRealIncomeRequest(trainerStudentBalanceId, amount, trainerStudentIncomeLabel, virtualTxId)

            return true
        },

        async getTrainerRealIncomes() {
            const res = await getRealIncomesByBalanceIdRequest(this.trainer.student_balance_id)
            this.allTrainerIncomes = res.data.real_incomes
        },

        async getTrainerTransactions() {
            const resStudentBalance = await getTransactionsByBalanceIdRequest(this.trainer.student_balance_id)
            const resServiceBalance = await getTransactionsByBalanceIdRequest(this.trainer.service_balance_id)
            this.allTrainerTransactions = [...resStudentBalance.data.transactions, ...resServiceBalance.data.transactions]
        }
    }
})