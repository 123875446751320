<template>
    <div>
        <div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <img src="../assets/good_luck_wish.webp">
            <div class="text-center text-[#FFF] font-[18px]">Тренування розпочато!</div>
        </div>
        <button @click="$router.push('/')" class="bg-[#4EAE3B] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">На головну</div>

            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#fff"/>
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'GoodTrainingWish'
    }
</script>
