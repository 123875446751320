<template>
    <div class="flex justify-between items-center w-full  px-[58px] py-[10px]">
        <div class="text-zinc-100 text-[20px] font-extrabold">
            {{ pageName }}
        </div>
        <div class="flex">
            <div class="text-[#A1D9F7] mr-[35px] text-[15px] font-medium flex justify-center items-center">
                <div>{{ store.trainer.name + ' ' + store.trainer.surname }}</div>
            </div>
            <div class="w-[46px] overflow-hidden h-[46px] flex items-center justify-center rounded-full border-[#A1D9F7] border-[1.5px]">
                <img v-if="store.trainer.photo?.length > 0" :src="apiURL + 'static/' + store.trainer.photo">
                <div v-else class="w-full h-full bg-[#A1D9F7] bg-opacity-40 text-[#A1D9F7] font-semibold flex justify-center items-center">
                    {{ store.trainer.name[0] + store.trainer.surname[0] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'Header',
        
        setup() {
            const pageName = ref('')
            const store = useMainStore()
            const router = useRouter()
            const route = useRoute()
            const apiURL = ref(process.env.VUE_APP_API_URL)

            onMounted(() => {
                switch(route.meta.component) {
                    case 'MainView':
                        pageName.value = 'Показники'
                        break
                }
            })

            return {
                store,
                pageName,
                apiURL
            }
        },

        components: {

        }
    }
</script>

<style lang="scss" scoped>

</style>