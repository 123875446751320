<template>
    <svg :width="fullWidth" :height="height">
        <rect :key="index" :x="0" :y="0" :width="firstPartWidth" :height="height - 15" fill="#4EAE3B" />
        <rect :key="index" :x="firstPartWidth" :y="0" :width="secondPartWidth" :height="height - 15" fill="#3B9BF4" />
        <text :key="index" :x="borderGap(firstPart) + 2" :y="height" text-anchor="middle" fill="#4EAE3B" font-size="12px">{{ firstValue }}</text>
        <text :key="index" :x="fullWidth - borderGap(secondPart) -2" :y="height" text-anchor="middle" fill="#3B9BF4" font-size="12px">{{ secondValue }}</text>
    </svg>
</template>

<script>
export default {
    props: {
        firstPart: {
            type: Number,
            required: true,
            default: 50
        },
        firstValue: {
            type: Number,
            required: true,
            default: 50
        },
        secondValue: {
            type: Number,
            required: true,
            default: 53330
        },
        secondPart: {
            type: Number,
            required: true,
            default: 50
        },
        fullWidth: {
            type: Number,
            required: true,
            default: 240
        },
        height: {
            type: Number,
            default: 30,
        },
    },
    computed: {
        firstPartWidth() {
            return (this.fullWidth / 100) * this.firstPart;
        },
        secondPartWidth() {
            return (this.fullWidth / 100) * this.secondPart;
        },
        borderGap() {
            return value => `${value}`.length * 5;
        },
    },
};
</script>